import { ScrollArea } from '@naamdeo/ui/components/scroll-area';
import { cn } from '@naamdeo/ui/lib/utils';
import { useAtom } from 'jotai';
import { ReactNode } from 'react';
import { Footer } from '~/components/admin-panel/footer';
import { Sidebar } from '~/components/admin-panel/sidebar';
import { sidebarStateAtom } from '~/state/sidebar';

export type AdminPanelLayoutProps = {
  children: ReactNode;
};

export default function AdminPanelLayout({ children }: AdminPanelLayoutProps) {
  const [sidebarState] = useAtom(sidebarStateAtom);

  return (
    <>
      <Sidebar />
      <main
        className={cn(
          'h-[calc(100vh_-_56px)] min-h-[calc(100vh_-_56px)] bg-zinc-50 transition-[margin-left] duration-300 ease-in-out dark:bg-zinc-900',
          sidebarState.isOpen === false ? 'lg:ml-[90px]' : 'lg:ml-72'
        )}
      >
        <ScrollArea className="h-full">{children}</ScrollArea>
      </main>
      <footer
        className={cn(
          'transition-[margin-left] duration-300 ease-in-out',
          sidebarState.isOpen === false ? 'lg:ml-[90px]' : 'lg:ml-72'
        )}
      >
        <Footer />
      </footer>
    </>
  );
}
